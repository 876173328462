import { useNavigate, useParams } from "react-router-dom";
import { useQuery } from "react-query";
import { Alert, Progress, Spinner } from "reactstrap";

import { Footer } from "../components/Footer";
import { getUserLocalStorage } from "../context/AuthProvider/util";

import { Modal } from '../components/Modal';

import info from "../assets/images/people.svg";

import "../styles/diabetesHipertensao.scss";
import { Api } from "../services/api";
import { Header } from "../components/Header";

import { Bar } from "../charts/Bar";
import { Donut } from "../charts/Donut";
import { Pie } from "../charts/Pie";
import { getNomeUbs } from "../utils";
import { useState } from "react";

type TModal = {
    loaded: number;
    tipo?: string;
    cnes?: string | undefined
}

type PainelParams = {
    id: string;
}

type TypeUbs = {
    label: string;
    value: number | string;
};

type Lista = {
    co_dim_unidade_saude_1: number;
    no_unidade_saude: string;
    nu_cnes: number;
}

type ResponseDataListUbs = {
    data: Lista[];
}

export function Hipertensao() {
    let navigate = useNavigate();
    const user = getUserLocalStorage();
    const { id } = useParams<PainelParams>();
    const [showModal, setShowModal] = useState(false);
    const [data, setData] = useState<TModal>({ loaded: 0 });

    let paramRoute = id ? id : 'all';

    const wait = (milliseconds: number) => {
        return new Promise((resolve) => setTimeout(resolve, milliseconds));
    };

    const getData = async (idModal: number, tipo?: string) => {
        await wait(100);
        setData({ loaded: idModal, tipo, cnes: id });
    };

    //get nome ubs
    const { data: dataUbs, isLoading: isLoadingUbs } = useQuery('ubs', async () => {
        const response = await Api.get<ResponseDataListUbs>('get-units')
        const data = response.data

        const listData: TypeUbs[] = data.data.map((ubs) => {
            return {
                "label": ubs.no_unidade_saude,
                "value": ubs.nu_cnes,
            }
        })

        return listData
    }, {
        staleTime: 1000 * 60 * 10, //10 minutos
    });

    const nomeUbs = !isLoadingUbs && id ? getNomeUbs(dataUbs, id) : '-';

    const { data: dataHipertensao, isLoading: isLoadingHipertensao, error: errorHipertensao } = useQuery(['hipertensao', paramRoute], async () => {
        //let path = id ? `pregnants/per-weeks/${id}` : 'pregnants/per-weeks';
        //const response = await Api.get(path);
        //const responseData = response.data;

        return {
            "0 a 17 anos": {
                "NaN": 40,
                "Rural": 16,
                "Urbano": 90
            },
            "18 a 29 anos": {
                "NaN": 389,
                "Rural": 97,
                "Urbano": 292
            },
            "30 a 44 anos": {
                "NaN": 582,
                "Rural": 174,
                "Urbano": 766
            },
            "45 a 59 anos": {
                "NaN": 521,
                "Rural": 162,
                "Urbano": 670
            },
            "60 + anos": {
                "NaN": 330,
                "Rural": 86,
                "Urbano": 396
            }
        }

        //return responseData.data;
    }, {
        staleTime: 1000 * 60 * 10, //10 minutos
    });

    const { data: dataHipertensaoIndicators, isLoading: isLoadingHipertensaoIndicators, error: errorHipertensaoIndicators } = useQuery(['hipertensao-indicators', paramRoute], async () => {
        //let path = id ? `pregnants/prenatal-indicators/${id}` : 'pregnants/prenatal-indicators';
        //const response = await Api.get(path);
        //const responseData = response.data;
        //const arrData = Object.entries(responseData.data);

        const dataTeste = [
            [
                "Infarto Agudo do Miocárdio",
                {
                    "com_consulta": 68,
                    "sem_consulta": 32
                }
            ],
            [
                "Acidente Vascular Encefálico",
                {
                    "com_consulta": 78,
                    "sem_consulta": 22
                }
            ],
            [
                "Doença Renal",
                {
                    "com_consulta": 89,
                    "sem_consulta": 11
                }
            ],
            [
                "DoençaCoronariana",
                {
                    "com_consulta": 82,
                    "sem_consulta": 12
                }
            ],
            [
                "Doença Cerebrovascular",
                {
                    "com_consulta": 57,
                    "sem_consulta": 43
                }
            ]
        ];

        return dataTeste;
        //return arrData;
    }, {
        staleTime: 1000 * 60 * 10, //10 minutos
    });

    const { data: dataHipertensaoFactors, isLoading: isLoadingHipertensaoFactors, error: errorHipertensaoFactors } = useQuery(['hipertensao-factors', paramRoute], async () => {
        //let path = id ? `pregnants/obstetrics-factors/${id}` : 'pregnants/obstetrics-factors';
        //const response = await Api.get(path);
        //const responseData = response.data;
        //const arrData = Object.entries(responseData.data);

        const arrData = [
            [
                "Baixo Peso",
                {
                    "limite": "< 18,5",
                    "com_consulta": 68,
                    "sem_consulta": 32
                }
            ],
            [
                "Peso Normal",
                {
                    "limite": "18,5 a 24,9",
                    "com_consulta": 78,
                    "sem_consulta": 22
                }
            ],
            [
                "Excesso de Peso",
                {
                    "limite": "25,0 a 29,9",
                    "com_consulta": 82,
                    "sem_consulta": 18
                }
            ],
            [
                "Obesidade",
                {
                    "limite": "> 30,0",
                    "com_consulta": 57,
                    "sem_consulta": 43
                }
            ]
        ]

        return arrData;
    }, {
        staleTime: 1000 * 60 * 10, //10 minutos
    });

    //exams table
    const { data: dataExamsTable, isLoading: isLoadingExamsTable, error: errorExamsTable } = useQuery(['hipertensao-exams-table', paramRoute], async () => {
        //let path = id ? `pregnants/exams-table/${id}` : 'pregnants/exams-table';
        //const response = await Api.get(path);
        //const data = response.data;
        const dataTeste = [
            {
                "solicitados": 2,
                "avaliados": 1,
                "tipo": "Aferição de PA"
            },
            {
                "solicitados": 7,
                "avaliados": 0,
                "tipo": "Glicemia"
            },
            {
                "solicitados": 0,
                "avaliados": 2,
                "tipo": "Creatinina"
            },
            {
                "solicitados": 0,
                "avaliados": 2,
                "tipo": "EAS/EQU (urina rotina)"
            },
            {
                "solicitados": 2,
                "avaliados": 0,
                "tipo": "Sódio e potássio"
            },
            {
                "solicitados": 3,
                "avaliados": 3,
                "tipo": "Colesterol total"
            },
            {
                "solicitados": 1,
                "avaliados": 2,
                "tipo": "Hemograma"
            },
            {
                "solicitados": 0,
                "avaliados": 0,
                "tipo": "Eletrocardiograma"
            }
        ];

        return dataTeste;

        //return data.data;
    }, {
        staleTime: 1000 * 60 * 10, //10 minutos
    });

    function handleToPainelUbs() {
        navigate(`/painel/${id}`);
    }

    function handleToPainelMunicipio() {
        navigate("/painelx");
    }

    const handleClick = (idModal: number) => {
        setData({ loaded: 0 });
        setShowModal(true);
        getData(idModal);
    };

    const random_hex_color_code = () => {
        let n = (Math.random() * 0x361949 * 1000000).toString(16);
        return '#' + n.slice(0, 6);
    };

    //exemplo
    const extratificacaoData = [
        {
            profissao: "Médico",
            total: 84
        },
        {
            profissao: "Enfermeiro",
            total: 64
        },
        {
            profissao: "Dentista",
            total: 39
        },
        {
            profissao: "Fisioterapeuta",
            total: 27
        },
        {
            profissao: "Terapeuta Ocupacional",
            total: 4
        },
        {
            profissao: "Farmacêutico",
            total: 2
        },
        {
            profissao: "Assistente Social",
            total: 1
        },
        {
            profissao: "Psicólogo",
            total: 3
        },
        {
            profissao: "Professor de Educação Física",
            total: 1
        },
        {
            profissao: "Fonoaudiólogo",
            total: 1
        }
    ]

    return (
        <div id="page-painel">
            <Header />

            <div className="contentWrapper">

                <hr className="linha my-4" />

                <h2>
                    {id ? (!isLoadingUbs ? nomeUbs : 'Carregando...') : user.municipio + " - " + user.uf} / Painel Hipertensão
                </h2>

                {showModal && <Modal data={data} setShowModal={setShowModal} />}

                <div className="container-fluid">
                    <div className="row gx-5">
                        <div className="col-12 col-lg-5">
                            <div className="painel-lateral">
                                <h4 className="mt-5 text-center">Pessoas com hipertensão por faixa etária</h4>
                                {isLoadingHipertensao ? (
                                    <div className="d-flex align-items-center justify-content-center">
                                        <Spinner size="sm" type="grow" className="me-2" />
                                        Carregando...
                                    </div>
                                ) : errorHipertensao ? (
                                    <div className="d-flex align-items-center justify-content-center">
                                        <Alert color="danger">
                                            Erro ao carregar dados.
                                        </Alert>
                                    </div>
                                ) : (
                                    <Bar data={dataHipertensao} titulo="Total de pessoas com hipertensão no município" />
                                )}
                            </div>

                            <div className="painel-lateral">
                                <h4 className="mt-5 text-center">Pessoas com hipertensão por sexo</h4>
                                {isLoadingHipertensao ? (
                                    <div className="d-flex align-items-center justify-content-center">
                                        <Spinner size="sm" type="grow" className="me-2" />
                                        Carregando...
                                    </div>
                                ) : errorHipertensao ? (
                                    <div className="d-flex align-items-center justify-content-center">
                                        <Alert color="danger">
                                            Erro ao carregar dados.
                                        </Alert>
                                    </div>
                                ) : (
                                    <Bar data={dataHipertensao} titulo="Total de pessoas com hipertensão no município" />
                                )}
                            </div>

                            <div className="painel-lateral situacao-exames">
                                <h3 className="my-5 text-center">Situação dos exames nos últimos 12 meses</h3>

                                <div className="row gx-4 my-3">
                                    <div className="col-5 col-lg-6">

                                    </div>
                                    <div className="col col-lg-3">
                                        <div className="tipo p-2 text-center">Solicitação pendente</div>
                                    </div>
                                    <div className="col col-lg-3">
                                        <div className="tipo p-2 text-center">Avaliação pendente</div>
                                    </div>
                                </div>

                                {isLoadingExamsTable ? (
                                    <div className="d-flex align-items-center justify-content-center">
                                        <Spinner size="sm" type="grow" className="me-2" />
                                        Carregando...
                                    </div>
                                ) : errorExamsTable ? (
                                    <div className="d-flex align-items-center justify-content-center">
                                        <Alert color="danger">
                                            Erro ao carregar dados.
                                        </Alert>
                                    </div>
                                ) : (
                                    <>
                                        {dataExamsTable?.map((situacao: any, i: number) => (
                                            <div key={i} className="row gx-4 my-3">
                                                <div className="col-5 col-lg-6">
                                                    <div className="tipo p-2 bordas">{situacao.tipo}</div>
                                                </div>
                                                <div className="col col-lg-3">
                                                    <div className="tipo p-2 text-center bordas">{situacao.solicitados}</div>
                                                </div>
                                                <div className="col col-lg-3">
                                                    <div className="tipo p-2 text-center bordas">{situacao.avaliados}</div>
                                                </div>
                                            </div>
                                        ))}
                                    </>
                                )}
                            </div>
                        </div>

                        <div className="col-12 col-lg-7 d-flex flex-column">
                            <div className="painel-lateral">
                                <h4 className="mt-5 mb-4 text-center">Total de atendimento nos últimos 12 meses</h4>

                                <div className="d-flex flex-wrap flex-lg-nowrap justify-content-center">
                                    <div>
                                        <div className="container-atendimentos">
                                            <div className="titulo d-flex align-items-center">
                                                <img src={info} alt="Total de atendimento nos últimos 12 meses" className="info mx-2" />
                                            </div>
                                            <span className="total-trimestre ms-4">
                                                7.538
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="painel-secundario my-2">
                                <h4 className="my-4 text-center">Frequência de complicações relacionadas à hipertensão</h4>

                                <div className="d-flex flex-wrap flex-xl-nowrap justify-content-center">
                                    {isLoadingHipertensaoIndicators ? (
                                        <div className="d-flex align-items-center justify-content-center">
                                            <Spinner size="sm" type="grow" className="me-2" />
                                            Carregando...
                                        </div>
                                    ) : errorHipertensaoIndicators ? (
                                        <div className="d-flex align-items-center justify-content-center">
                                            <Alert color="danger">
                                                Erro ao carregar dados.
                                            </Alert>
                                        </div>
                                    ) : (
                                        <>
                                            {dataHipertensaoIndicators?.map((indicador: any, i: number) => <Donut key={i} data={indicador} />)}
                                        </>
                                    )}
                                </div>

                                <button
                                    type="button"
                                    onClick={() => handleClick(5)}
                                    className="btn btn-primary mt-5">
                                    Boas práticas na assistência a pessoas com hipertensão
                                </button>
                            </div>

                            <div className="painel-secundario">
                                <h4 className="mt-5 mb-4 text-center">Adultos com hipertensão de acordo com o IMC</h4>

                                <div className="d-flex flex-wrap flex-lg-nowrap justify-content-center">
                                    {isLoadingHipertensaoFactors ? (
                                        <div className="d-flex align-items-center justify-content-center">
                                            <Spinner size="sm" type="grow" className="me-2" />
                                            Carregando...
                                        </div>
                                    ) : errorHipertensaoFactors ? (
                                        <div className="d-flex align-items-center justify-content-center">
                                            <Alert color="danger">
                                                Erro ao carregar dados.
                                            </Alert>
                                        </div>
                                    ) : (
                                        <>
                                            {dataHipertensaoFactors?.map((diabete: any, i: number) => <Pie key={i} data={diabete} />)}
                                        </>
                                    )}
                                </div>
                            </div>

                            <div className="painel-secundario">
                                <h4 className="mt-5 mb-4 text-center">Extratificação de atendimentos por profissional</h4>
                                <div className="w-100">
                                    {extratificacaoData?.map((item: any, i: number) => (
                                        <div key={i} className="d-flex align-items-center mt-2">
                                            <div className="container-extratificacao-atendimentos">
                                                <span className="profissao-nome">{item.profissao}</span>
                                                <Progress value={item.total} className="w-75" barStyle={{ backgroundColor: random_hex_color_code() }} />
                                            </div>
                                            <span className="total ms-2">{item.total}%</span>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="d-flex flex-column align-items-center mt-5">
                    {id &&
                        <button
                            type="button"
                            onClick={handleToPainelUbs}
                            className="btn btn-light my-2">
                            Voltar para o Painel da UBS
                        </button>
                    }
                    <button
                        type="button"
                        onClick={handleToPainelMunicipio}
                        className="btn btn-primary my-4">
                        Visualizar dados do painel do Município
                    </button>
                </div>
            </div>

            <Footer />
        </div>
    )
}
